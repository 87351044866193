import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/index.css';
import { Helmet } from "react-helmet";
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <head></head>

    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&icon_names=download"
      />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:ital,wght@0,400..700;1,400..700&display=swap"
        rel="stylesheet"
      />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Slackside+One&display=swap"
        rel="stylesheet"
      />

      <title>Isaac Jenemann</title>
      <meta charset="utf-8"></meta>
      <link
        rel="author"
        href="https://www.linkedin.com/in/isaac-jenemann-842b85220/"
      />
      <meta
        name="description"
        content="Creative Portfolio for my Set and Theater Work"
      />
      <meta
        name=""
        content="Code and Design by me using React, Figma, and VSCode"
      />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />

      <link rel="canonical" href="https://www.isaacjenemann.com/" />
    </Helmet>

    <App />
  </React.StrictMode>
);
