import "../CSS/Body.css";

function Recent({changeProject}) {
  return (
    <>
      <div className="project-list">
        <a
          className="project-titles"
          onClick={() => changeProject("Buzzkill")}
          href="recent/buzzkill"
        >
          Buzzkill
        </a>
        <br />
        <a
          className="project-titles"
          onClick={() => changeProject("Sic")}
          href="recent/sic"
        >
          [sic]
        </a>
        <br />
        <a
          className="project-titles"
          href="recent/midsummer"
          onClick={() => changeProject("Midsummer")}
        >
          A Midsummer Night's Dream
        </a>
        <br />
        <a
          className="project-titles"
          href="recent/psychopsychotic"
          onClick={() => changeProject("Psycho")}
        >
          Psychopsychotic
        </a>
        <br />
        <a
          className="project-titles"
          onClick={() => changeProject("IntoTheWoods")}
          href="recent/intothewoods"
        >
          Into The Woods
        </a>
        <br />
        <a
          className="project-titles"
          href="recent/stupidfkngbird"
          onClick={() => changeProject("StupidFknBird")}
        >
          Stupid Fucking Bird
        </a>
      </div>
    </>
  );
}

export default Recent;
