import React, { useState, useEffect } from "react";

const ResponsiveWarning = ({ minWidth = 768 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < minWidth);

  const handleResize = () => {
    setIsMobile(window.innerWidth < minWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile && (
        <div className= "warning">
          <h1 id="warning-text">
            Like zoinks Scoob, i'm still configuring the site for this size screen.
          </h1>
        </div>
      )}
    </>
  );
};



export default ResponsiveWarning;
