import "./CSS/App.css";
import "./CSS/Body.css";
import "./CSS/Mobile.css";

import { useState } from "react";
import Home from "./Components/Home";
import Menu from "./Components/Menu";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Recent from "./Components/Recent";
import Resume from "./Components/Resume"
import StupidFkingBird from "./Components/Projects/StupidFkingBird";
import Psycho from "./Components/Projects/Psychopsychotic";
import Sic from "./Components/Projects/Sic";
import Buzzkill from "./Components/Projects/Buzzkill";
import Midsummer from "./Components/Projects/Midsummer";
import IntoTheWoods from "./Components/Projects/IntoTheWoods";
import {projectImages} from "./Components/ImageData"; 
import ResponsiveWarning from "./Components/ResponsiveWarning";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ProjectMenu from "./Components/ProjectMenu";


function App() {
  
  const [currentProject, setCurrentProject] = useState("Buzzkill");
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % projectImages[currentProject].length
    );
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + projectImages[currentProject].length) %
        projectImages[currentProject].length
    );
  };

  const changeProject = (project) => {
    setCurrentProject(project);
    setCurrentIndex(0); 
  };

  
  const resizeOps = () => {
    document.documentElement.style.setProperty(
      "--vh",
      window.innerHeight * 0.01 + "px"
    );
  };

  resizeOps();
  window.addEventListener("resize", resizeOps);



  return (
    <>
      <ResponsiveWarning minWidth={800} />
      <Router>
        <div className="main">
          <ProjectMenu nextImage={nextImage} prevImage={prevImage} />
          <div className="content">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route
                path="/recent"
                element={<Recent />}
                changeProject={changeProject}
              />
              <Route path="/resume" element={<Resume />} />

              <Route path="*" element={<Navigate to="/" />} />
              <Route
                path="/recent/Buzzkill"
                element={
                  <Buzzkill
                    imageSrc={projectImages.Buzzkill[currentIndex]}
                    nextImage={nextImage}
                  />
                }
              />
              <Route
                path="/recent/sic"
                element={
                  <Sic
                    imageSrc={projectImages.Sic[currentIndex]}
                    nextImage={nextImage}
                  />
                }
              />
              <Route
                path="/recent/psychopsychotic"
                element={
                  <Psycho
                    imageSrc={projectImages.Psycho[currentIndex]}
                    nextImage={nextImage}
                  />
                }
              />
              <Route
                path="/recent/intothewoods"
                element={
                  <IntoTheWoods
                    imageSrc={projectImages.IntoTheWoods[currentIndex]}
                    nextImage={nextImage}
                  />
                }
              />
              <Route
                path="/recent/StupidFkngBird"
                element={
                  <StupidFkingBird
                    imageSrc={projectImages.StupidFkingBird[currentIndex]}
                    nextImage={nextImage}
                  />
                }
              />
              <Route
                path="/recent/Midsummer"
                element={
                  <Midsummer
                    imageSrc={projectImages.Midsummer[currentIndex]}
                    nextImage={nextImage}
                  />
                }
              />
            </Routes>
            <Menu changeProject={changeProject} />
          </div>
          <div />
        </div>
      </Router>
    </>
  );
}

export default App;
