import "../../CSS/Body.css";

function StupidFkingBird({ imageSrc }) {
  return (
    <>
      <div className="project-image-container">
        <img className="project-image" src={imageSrc} />
      </div>
      <div className="project-body">
        <h1 className="project-text" id="project-role">
          Scenic Design
        </h1>
        <h1 className="project-text" id="project-title">
          STUPID FUCKING BIRD
        </h1>

        <h1 className="project-text" id="project-billing">
          By Aaron Posner
          <br />
          <br />
          The Downspace|2022
          <br />
          Directed: Medora Groff
          <br />
          Lighting Design: Yana Tsyvis
          <br />
          Costumes: Ines Sawiris and Alden Forbes
          <br />
          Sound: Juliana Morgado Brito
        </h1>
      </div>
    </>
  );
}

export default StupidFkingBird;
