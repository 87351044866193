import "../../CSS/Body.css";

function Sic({ imageSrc }) {
  return (
    <>
      <div className="project-image-container">
        <img className="project-image" src={imageSrc} />
      </div>
      <div className="project-body">
        <h1 className="project-text" id="project-role">
          Scenic Design
        </h1>
        <h1 className="project-text" id="project-title">
          [sic]
        </h1>

        <h1 className="project-text" id="project-billing">
          By Melissa James Gibson
          <br />
          <br />
          Fishman Studio | 2024
          <br />
          Director: Daniel Quinter
          <br />
          Lighting: Lizi Zhang
          <br />
          Costumes: Wenhao Cai
          <br />
          Sound: Juliana Morgado Brito
        </h1>
      </div>
    </>
  );
}

export default Sic;
