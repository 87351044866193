import "../CSS/Body.css";

function Home() {
  return (
    <>
      <h1 id="name">
        ISAAC JENEMANN <br />
        <span id="creative">
          <span id="c">c</span>
          <span id="r">r</span>
          <span id="e">e</span>ative
        </span>
      </h1>
    </>
  );
}

export default Home;
