import React, { useState, useEffect } from "react";
import "../CSS/Body.css";

const pdf = `${process.env.PUBLIC_URL}/IsaacJenemann.pdf#toolbar=0&zoom=60`;
const isaacjenemann = `${process.env.PUBLIC_URL}/IsaacJenemann.pdf#toolbar`;
const imageSrc = `${process.env.PUBLIC_URL}/IsaacJenemann.png`; // Replace with your image file

function Resume() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700); // Initial check for mobile

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700); // Update state based on window width
    };

    window.addEventListener("resize", handleResize); // Add resize event listener

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <img
            src={imageSrc}
            alt="Resume"
            className="pdf-image" // Add your CSS class here for styling
          />
          <a
            href={isaacjenemann}
            target="_blank"
            rel="noreferrer"
            className="download-button"
            download="ResumeForIsaacJenemann"
          >
            <span class="material-symbols-outlined">download</span>
          </a>
        </>
      ) : (
        <iframe src={pdf} title="PDF Viewer" className="PDF">
          This browser does not support PDFs. Please download the PDF to view
          it: <a href={pdf}>Download PDF</a>.
        </iframe>
      )}
    </>
  );
}

export default Resume;
