import "../../CSS/Body.css";

function Psycho({ imageSrc }) {
  return (
    <>
      <div className="project-image-container">
        <img className="project-image" src={imageSrc} />
      </div>
      <div className="project-body">
        <h1 className="project-text" id="project-role">
          Scenic Design
        </h1>
        <h1 className="project-text" id="project-title">
          PSYCHOPSYCHOTIC
        </h1>

        <h1 className="project-text" id="project-billing">
          By Alexa Derman
          <br />
          <br />
          Production Workshop | 2023
          <br />
          Directed: Josephine Miller
          <br />
          Lighting Design: Lizi Zhang
          <br />
          Technical Direction: Sam Giamatti
          <br />
          Sound: Juliana Morgado Brito
        </h1>
      </div>
    </>
  );
}

export default Psycho;
