import "../../CSS/Body.css";

function IntoTheWoods({ imageSrc }) {
  return (
    <>
      <div className="project-image-container">
        <img className="project-image" src={imageSrc} />
      </div>
      <div className="project-body">
        <h1 className="project-text" id="project-role">
          Technical Direction
        </h1>
        <h1 className="project-text" id="project-title">
          INTO THE WOODS
        </h1>
        <h1 className="project-text" id="project-billing">
          Music and Lyrics by Stephen Sondheim
          <br />
          Book by James Lapine
          <br />
          <br />
          Fishman Studio | 2023
          <br />
          Director: Ethan Mullen & Elise Aronson
          <br />
          Set Design: Juliana Morgado-Brito
          <br />
          Lighting Design: Lizi Zhang
          <br />
          Costumes: Lulu Cavicchi
          <br /> Sound: Milan Capoor
        </h1>
      </div>
    </>
  );
}

export default IntoTheWoods;
