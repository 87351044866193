import "../CSS/Body.css";

function Contact() {
  return (
    <div className="contact-text">
      <a id="contact-email" href="mailto:isaac.jenemann@gmail.com">
        isaac.jenemann@gmail.com
      </a>
      <br />
      <a id="contact-phone" href="tel:+18028817144">
        1.802.881.7144
      </a>
      <br />

      {/* Social Links Section */}
      <div className="social-links">
        <a
          href="https://open.spotify.com/user/isaacjenemann?si=255c1cf8fa4a48ab"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="contact-images"
            src="./Assets/spotify.png"
            alt="Spotify"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/isaac-jenemann-842b85220/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="contact-images"
            src="./Assets/linkedin.png"
            alt="LinkedIn"
          />
        </a>
        <a
          href="https://www.instagram.com/isaacjenemann/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="contact-images"
            src="./Assets/instagram.png"
            alt="Instagram"
          />
        </a>
      </div>
    </div>
  );
}

export default Contact;
