export const projectImages = {
  Buzzkill: [
    "/Assets/Buzzkill.jpeg",
    "/Assets/Buzzkill1.jpeg",
    "/Assets/Buzzkill2.jpeg",
    "/Assets/Buzzkill3.jpeg",
    "/Assets/Buzzkill4.jpeg",
    "/Assets/Buzzkill5.jpeg",
  ],

  Psycho: [
    "/Assets/psycho1.jpeg",
    "/Assets/psycho5.jpeg",
    "/Assets/psycho2.jpeg",
    "/Assets/psycho9.jpeg",
    "/Assets/psycho4.jpeg",
    "/Assets/psycho6.jpeg",
  ],

  IntoTheWoods: [
    "/Assets/woods5.jpeg",
    "/Assets/woods2.jpeg",
    "/Assets/woods3.jpeg",
    "/Assets/woods4.jpeg",
    "/Assets/woods1.jpeg",
    "/Assets/woods6.jpeg",
  ],

  StupidFkingBird: [
    "/Assets/stupid5.jpeg",
    "/Assets/stupid6.jpeg",
    "/Assets/stupid3.jpeg",
    "/Assets/stupid1.jpeg",
    "/Assets/stupid4.jpeg",
    "/Assets/stupid2.jpeg",
  ],

  Midsummer: [
    "/Assets/Midsummer2.jpeg",
    "/Assets/Midsummer1.jpeg",
    "/Assets/Midsummer3.jpeg",
    "/Assets/Midsummer6.jpeg",
    "/Assets/Midsummer4.jpeg",
    "/Assets/Midsummer5.jpeg",
  ],

  Sic: [
    "/Assets/sic2.jpeg",
    "/Assets/sic3.jpeg",
    "/Assets/sic6.jpeg",
    "/Assets/sic4.jpeg",
    "/Assets/sic1.jpeg",
    "/Assets/sic5.jpeg",
  ],
};



