import "../../CSS/Body.css";

function Midsummer({ imageSrc }) {
  return (
    <>
      <div className="project-image-container">
        <img className="project-image" src={imageSrc} />
      </div>
      <div className="project-body">
        <h1 className="project-text" id="project-role">
          Set Design
        </h1>
        <h1 className="project-text" id="project-title">
          A MIDSUMMER
          <br />
          NIGHT'S DREAM
        </h1>

        <h1 className="project-text" id="project-billing">
          By William Shakespeare
          <br />
          <br />
          Production Workshop | 2024
          <br />
          Director: Fiona Mathews & Liliana Greyf
          <br />
          Lighting: Milan Capoor
          <br />
          Technical Direction: Sofia Tazi & Keelin Gaughan
          <br />
          Sound: Juliana Morgado Brito
        </h1>
      </div>
    </>
  );
}

export default Midsummer;
