import "../CSS/Body.css";

function About() {
  return (
    <>
      <p id="about-text">
        <span id="about-initials">i</span>saac{" "}
        <span id="about-initials">j</span>enemann is a designer and filmmaker,
        occasionally a line cook, and always a learner. A background in
        performance paired with hands-on experience in technical direction,
        carpentry, and lighting design informs Isaac’s work and allows a
        practical approach to using space as a storyteller. He finds artistic
        inspiration in the everyday and is constantly pondering how a chest
        might become a bed or a bunch of string, a forest. He's currently based
        in Harlem, NY.
      </p>
      <img id="about-image" src="./Assets/isaacDoodle.png" />
    </>
  );
}

export default About;
