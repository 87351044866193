import "../../CSS/Body.css";
import { useEffect, useState } from "react";

function Buzzkill({ imageSrc }) {
  const [currentImage, setCurrentImage] = useState(imageSrc);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  const IMAGE_DISPLAY_DURATION = 5000; // Duration to display each image (in milliseconds)

  // Check screen size on resize
  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 800;
      setIsSmallScreen(smallScreen);
      console.log(`Window resized: ${window.innerWidth}, isSmallScreen: ${smallScreen}`);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setCurrentImage(imageSrc); // Update the current image

    let displayTimer;
    if (isSmallScreen) {
      displayTimer = setTimeout(() => {
        console.log("Switching to next image");
        // Mock nextImage function for testing
        setCurrentImage(prev => (prev === "image1.jpg" ? "image2.jpg" : "image1.jpg"));
      }, IMAGE_DISPLAY_DURATION);
    }

    return () => {
      clearTimeout(displayTimer);
    };
  }, [imageSrc, isSmallScreen]);

  return (
    <>
      <div className="project-image-container">
        <img
          src={currentImage}
          className="project-image"
          alt="Project Image"
        />
      </div>
      <div className="project-body">
        <h1 className="project-text" id="project-role">
          Artistic Direction
        </h1>
        <h1 className="project-text" id="project-title">
          BUZZKILL
        </h1>
        <h1 className="project-text" id="project-billing">
          Independent | 2024
          <br />
          Script, Director: Zoe Redlich & Celine Dipp
          <br />
          Director of Photography: Nat Mitchell & Isaac McKenna
        </h1>
      </div>
    </>
  );
}

export default Buzzkill;
